import React from 'react'

const About = () => {
  return (
    <div className='text-center py-10 text-3xl'>
      <h1>About</h1>
    </div>
  )
}

export default About
